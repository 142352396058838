// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hire-dedicated-angularjs-developers-js": () => import("./../../../src/pages/hire-dedicated-angularjs-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-angularjs-developers-js" */),
  "component---src-pages-hire-dedicated-chatbot-development-team-js": () => import("./../../../src/pages/hire-dedicated-chatbot-development-team.js" /* webpackChunkName: "component---src-pages-hire-dedicated-chatbot-development-team-js" */),
  "component---src-pages-hire-dedicated-graphql-developers-js": () => import("./../../../src/pages/hire-dedicated-graphql-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-graphql-developers-js" */),
  "component---src-pages-hire-dedicated-javascript-developers-js": () => import("./../../../src/pages/hire-dedicated-javascript-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-javascript-developers-js" */),
  "component---src-pages-hire-dedicated-mean-stack-developers-js": () => import("./../../../src/pages/hire-dedicated-mean-stack-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-mean-stack-developers-js" */),
  "component---src-pages-hire-dedicated-mern-stack-developers-js": () => import("./../../../src/pages/hire-dedicated-mern-stack-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-mern-stack-developers-js" */),
  "component---src-pages-hire-dedicated-messaging-app-development-team-js": () => import("./../../../src/pages/hire-dedicated-messaging-app-development-team.js" /* webpackChunkName: "component---src-pages-hire-dedicated-messaging-app-development-team-js" */),
  "component---src-pages-hire-dedicated-mobile-app-development-team-js": () => import("./../../../src/pages/hire-dedicated-mobile-app-development-team.js" /* webpackChunkName: "component---src-pages-hire-dedicated-mobile-app-development-team-js" */),
  "component---src-pages-hire-dedicated-mongodb-developers-js": () => import("./../../../src/pages/hire-dedicated-mongodb-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-mongodb-developers-js" */),
  "component---src-pages-hire-dedicated-mvp-development-team-js": () => import("./../../../src/pages/hire-dedicated-mvp-development-team.js" /* webpackChunkName: "component---src-pages-hire-dedicated-mvp-development-team-js" */),
  "component---src-pages-hire-dedicated-nodejs-developers-js": () => import("./../../../src/pages/hire-dedicated-nodejs-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-nodejs-developers-js" */),
  "component---src-pages-hire-dedicated-product-development-team-js": () => import("./../../../src/pages/hire-dedicated-product-development-team.js" /* webpackChunkName: "component---src-pages-hire-dedicated-product-development-team-js" */),
  "component---src-pages-hire-dedicated-react-native-developers-js": () => import("./../../../src/pages/hire-dedicated-react-native-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-react-native-developers-js" */),
  "component---src-pages-hire-dedicated-reactjs-developers-js": () => import("./../../../src/pages/hire-dedicated-reactjs-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-reactjs-developers-js" */),
  "component---src-pages-hire-dedicated-vuejs-developers-js": () => import("./../../../src/pages/hire-dedicated-vuejs-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-vuejs-developers-js" */),
  "component---src-pages-hire-dedicated-web-app-development-team-js": () => import("./../../../src/pages/hire-dedicated-web-app-development-team.js" /* webpackChunkName: "component---src-pages-hire-dedicated-web-app-development-team-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

